<template>
  <ClientOnly>
    <Teleport
      defer
      to="#teleports"
    >
      <dialog
        v-bind="$attrs"
        ref="dialog"
        class="dialog"
        :class="`dialog--${dialogType}`"
        :open="isOpen"
        :aria-hidden="!isOpen"
        @click="($event: MouseEvent) => onClickOutside($event)"
        @close="closeDialog"
        @transitionend="$emit('transitionend')"
      >
        <!-- <form
          :id="dialogName"
          class="dialog__form"
          method="dialog" /> -->

        <header class="dialog__header">
          <slot
            name="dialogHeader"
            v-bind="{ dialogName, open, close }"
          />
          <slot
            name="close"
            v-bind="{ dialogName, open, close }"
          >
            <button
              type="button"
              class="dialog__close"
              @click="close"
            >
              <IconClose class="icon icon--close" />
            </button>
            <!-- <button
              type="submit"
              class="dialog__close"
              :form="dialogName">
              <IconClose class="icon icon--close" />
            </button> -->
          </slot>
        </header>

        <div class="dialog__content hide-scrollbar ">
          <slot v-bind="{ open, close }" />
        </div>

        <footer
          v-if="$slots.dialogFooter"
          class="dialog__footer"
        >
          <slot
            name="dialogFooter"
            v-bind="{ dialogName, open, close }"
          />
        </footer>
      </dialog>
    </Teleport>
  </ClientOnly>
</template>

<script setup lang="ts">
/*
 * Types of modals
 * - (HTML dialog) dialog, default behavior is more like a tooltip or notification
 * - (HTML dialog) modal - should block every interaction with the rest of the page
 * - panel - variant of modal that slides in from aside
 */

type Props = {
  name?: string
  dialogType?: 'dialog' | 'modal' | 'fullscreen' | 'from-left' | 'from-right' | 'from-top' | 'from-bottom'
}

const props = withDefaults(defineProps<Props>(), {
  name: 'dialog',
  dialogType: 'modal',
})

const emit = defineEmits(['close'])

const { dialog, isOpen, open, close, onClickOutside, top } = useUiDialog(props.name, {
  type: props.dialogType,
})

const dialogName = computed(() => {
  return `dialog--${useDash(props.name)}`
})

const closeDialog = () => {
  window.scrollTo({
    top: top.value,
    behavior: 'auto',
  })

  isOpen.value = false

  emit('close')
}
</script>

<style src="~layers/app/components/Ui/Dialog.css" />
